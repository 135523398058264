const paginator = (items: Array<any>, currentLength: number, currentPage: number, pageSize = 10) => {
  const totalPages = Math.ceil(items.length / pageSize);
  const offset = (currentPage - 1) * pageSize;
  const offsetDistance = ((currentLength % pageSize));
  const paginatedItems = items.slice((offset + offsetDistance), currentPage * pageSize);
  if (totalPages >= currentPage) {
    return paginatedItems;
  }
  return [];
};

export default { paginator };
